#root {
  background-color: #13101c !important;
}
.table {
  --cui-table-bg: none;
}
.table thead {
  font-weight: 600;
}

/***********Tables View Styling****************/

.cardView {
  /* 
background: linear-gradient(111deg,#d2dbf0 7.02%,#ac83e4 19.07%,#eaa7d1 38.12%,#fece84 57.96%,#e28ca3 81.68%);

  backdrop-filter: blur(81%) grayscale(100%);  */

  padding: 1rem;
  border-radius: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
/* .cardView::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0), 
    rgba(128, 128, 128, 0.4), 
    rgba(255, 255, 255, 0.4), 
  );
  z-index: -1;
} */
.cardView h2 {
  color: white;
}

.cardView h6 {
  font-weight: 700;
  font-size: 14px;

  color: black;
}

.text-muted {
  font-size: 17px !important;
}
.profile-img {
  text-align: center;
}
.profile-img img {
  width: 150px; /* Set the desired width */
  height: 150px; /* Set the desired height */
  border-radius: 50%; /* Creates a circular mask */
  object-fit: cover;
}

.profile-content p {
  color: white;
  font-size: 18px;
  font-weight: 700;
}
.profile-content span {
  color: darkgray;
  font-weight: 700;
  font-size: 15px;
  padding-left: 2rem;
}

.gradient-custom {
  /* fallback for old browsers */
  background: linear-gradient(
    111deg,
    #d2dbf0 7.02%,
    #ac83e4 19.07%,
    #eaa7d1 38.12%,
    #fece84 57.96%,
    #e28ca3 81.68%
  );
}
.gradient-custom h5 {
  color: gray;
  border-bottom: 2px solid;
  display: inline-block;
}

/**************Footer**************/

.footer ul li a {
  text-decoration: none;
  font-size: 17px;
  color: lightgray !important;
}
.footer ul li a:hover {
  color: white !important;
}
.footer ul li {
  padding-left: 9px;
}
.footer {
  --cui-footer-bg: none;
  --cui-footer-border-color: var(--cui-border-color);
  --cui-footer-border: none;
  color: white;

  --cui-footer-padding-x: 0;
  --cui-footer-padding-y: 0.2;
}


@media (max-width: 576px) {
  .text-center-xs {
    text-align: center;
  }
}